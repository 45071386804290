const authconfig = {
  // Created from the Terraform template aws-auth-config.js.tpl
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: "us-east-1:c258bad5-ccdf-4dc7-897e-bf02fc13254c",
  
  // REQUIRED - Amazon Cognito Region
  region: "us-east-1",

  // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
  // Required only if it's different from Amazon Cognito Region
  identityPoolRegion: "us-east-1",

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: "us-east-1_wxRbwN2Xw",

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: "3ger0ju3ptepon90l94b3dkqcm",

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,
  
  // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
  // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
  signUpVerificationMethod: 'code', // 'code' | 'link' 
}

export default authconfig
