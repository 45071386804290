// NOTE - set the environment variable 'REACT_APP_FQDN' for local react development
import React, { useState,useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { 
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
  TextField,
  createTheme,
  ThemeOptions,
  ThemeProvider,
  useTheme 
} from '@mui/material';
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  // Button,
  View,
  Card,
} from "@aws-amplify/ui-react";
import { Amplify, API, Auth } from 'aws-amplify';
import authconfig from './aws-auth-config'
const extendedconfig = {
  Auth: {...authconfig},
}

var baseURL = `${window.location.href}`;
if (baseURL.indexOf('localhost') !== -1) {
    var remoteURL = `https://${process.env.REACT_APP_FQDN}/`;
} else {
    var remoteURL = baseURL;
}
var apiurl = decodeURI(remoteURL + "api");

Amplify.configure(extendedconfig)
Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'openvpnapi',
        endpoint: apiurl,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }
});

async function getData() {
return await API.get("openvpnapi", "/");
}

function App({ signOut }) {

  const columns = useMemo(
    () => [
      {
        accessorKey: 'common_name',
        header: 'Common Name',
      },
      {
        accessorKey: 'real_address',
        header: 'Real Address',
      },
      {
        accessorKey: 'virtual_address',
        header: 'Virtual Address',
      },
      {
        accessorKey: 'bytes_received',
        header: 'Bytes Received',
      },
      {
        accessorKey: 'bytes_sent',
        header: 'Bytes Sent',
      },
      {
        accessorKey: 'connected_since',
        header: 'Connected Since',
      },
      {
        accessorKey: 'connected_end',
        header: 'Connection Ended',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() == 'ACTIVE' ? theme.palette.success.light : theme.palette.info.main,
                // cell.getValue() < 50_000
                //   ? theme.palette.error.dark
                //   : cell.getValue() >= 50_000 && cell.getValue() < 75_000
                //   ? theme.palette.warning.dark
                //   : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#000',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {})}
          </Box>
        ),
      },
    ],
    [],
  );

  const [itemList, setitemList] = useState([]);
  useEffect(() => {
    const loaditemList = async () => {
      try {
        const itemList = await getData();
        setitemList(itemList)
        console.log(itemList)
        console.log(itemList[0]['vpn_users'])
      } catch(error) {
        // handle any error state, rejected promises, etc..
      }
    };
    loaditemList();
  }, []);

  return (
    <View className="App">
      {itemList?.map((object, index) =>
      <Card key={object.instance_name}>
        <h3 className="p-3 text-center">OpenVPN Server {object.instance_name} ({object.instance_public_ip})</h3>
          <p>Last updated: {object.last_modified}</p>
          <MaterialReactTable
            columns={columns}
            data={object.vpn_users}
          />
      </Card>
      )}
      <Card><Button onClick={signOut}>Sign Out</Button></Card>
      
    </View>
  );
}

export default withAuthenticator(App);